@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: Outfit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    min-width: 1200px;
}

img {
    vertical-align: middle;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
